import { DataFieldProps } from "atom/datafield/dataFieldComponents";
import type { InputProps } from "atom/input/inputComponents";
import { useBloc } from "@blac/react";
import { DateValue } from "@internationalized/date";
import React, { ChangeEvent, useEffect, useMemo } from "react";
import { z } from "zod";
import AutoFormBloc, {
  AutoFormRegisterProps,
  FormFieldState
} from "./AutoFormBloc";
import AutoFormContext from "./AutoFormContext";
import { Key } from "react-aria";
import { DateRange } from "react-aria";
import { AutocompleteProps } from "molecule/autocomplete/Autocomplete";

function useAutoFormFieldProps({
  bloc,
  parseType,
  name,
  ref
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bloc: AutoFormBloc<any>;
  parseType: typeof String | typeof Number | typeof Array<string>;
  name: string;
  ref: React.Ref<FormFieldState | null>;
}) {
  const fieldProps = useMemo(() => {
    if (bloc.props && parseType) {
      return bloc.registerAutoFormField(name, {
        type: parseType,
        ref: ref as { current: FormFieldState }
      });
    }
  }, [name, parseType, bloc.state.schema]);
  return fieldProps;
}

export default function useAutoFormField<T = DataFieldProps>(
  userDefinedProps: T & {
    name: string;
    onSelectionChange?: (value: Key) => void;
  },
  type: InputProps["type"] = "text",
  inputRef?: React.Ref<FormFieldState | null>
): [AutoFormRegisterProps<T> | undefined] {
  const ref = React.useRef<FormFieldState>(null);
  const { name } = userDefinedProps;
  React.useImperativeHandle(inputRef, () => ref.current, [ref]);

  const parseType = useMemo(() => {
    switch (type) {
      case "number":
      case "range":
      case "tel":
        return Number;
      case "checkbox":
        return Array<string>;
      default:
        return String;
    }
  }, [type]);

  const contextId = React.useContext(AutoFormContext);
  const [state, bloc] = useBloc(AutoFormBloc, {
    id: contextId
  });

  const fieldPropsGenerated = useAutoFormFieldProps({
    bloc,
    ref,
    parseType,
    name
  });

  const handleDateChange = (eventValue: DateValue | undefined) => {
    bloc.handleChange(name, eventValue);
    const fieldError = state.errors[name]?.[0];
    if (!bloc.props?.validateOnChange && !fieldError) return;

    const value: DateValue | undefined = !eventValue ? undefined : eventValue;

    // Initialize validation flag and error messages array
    // let isValid = false;
    let issues: (z.ZodIssue | undefined)[] = [];

    const fieldSchema = state.schema?.shape[name];
    // Attempt to parse the value using the field schema
    const parseResult = fieldSchema?.safeParse(value);
    if (!parseResult?.success) {
      issues = parseResult?.error.issues ?? [];
    }

    // Update the error messages array
    const current = (state.errors[name] ?? [])[0];
    const newIssues = issues[0];

    if (current?.code !== newIssues?.code || current?.message !== newIssues?.message) {
      bloc.patch({
        errors: { ...state.errors, [name]: issues }
      });
    }
  };

  const handleSelectChange = (key: Key) => {
    userDefinedProps.onSelectionChange?.(key);
    bloc.handleChange(name, key);
    const fieldError = state.errors[name]?.[0];
    if (!bloc.props?.validateOnChange && !fieldError) return;

    // Initialize validation flag and error messages array
    // let isValid = false;
    let issues: (z.ZodIssue | undefined)[] = [];

    const fieldSchema = state.schema?.shape[name];
    // Attempt to parse the value using the field schema
    const parseResult = fieldSchema?.safeParse(key);
    if (!parseResult?.success) {
      issues = parseResult?.error.issues ?? [];
    }

    // Update the error messages array
    const current = (state.errors[name] ?? [])[0];
    const newIssues = issues[0];

    if (current?.code !== newIssues?.code) {
      bloc.patch({
        errors: { ...state.errors, [name]: issues }
      });
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    bloc.handleChange(name, e.target.value);
    const fieldError = state.errors[name]?.[0];
    if (!bloc.props?.validateOnChange && !fieldError) return;
    let value: string | number | string[] = e.target.value;
    value = parseType(value);

    // Initialize validation flag and error messages array
    // let isValid = false;
    let issues: (z.ZodIssue | undefined)[] = [];

    const fieldSchema = state.schema?.shape[name];
    // Attempt to parse the value using the field schema
    const parseResult = fieldSchema?.safeParse(value);
    if (!parseResult?.success) {
      issues = parseResult?.error.issues ?? [];
    }

    const isSame =
      state.errors[name]?.[0]?.code === issues[0]?.code &&
      state.errors[name]?.[0]?.message === issues[0]?.message;

    if (!isSame) {
      bloc.patch({
        errors: { ...state.errors, [name]: issues }
      });
    }
  };

  const handleRadioChange = (key: string) => {
    bloc.handleChange(name, key);
    const fieldError = state.errors[name]?.[0];
    if (!bloc.props?.validateOnChange && !fieldError) return;

    // Initialize validation flag and error messages array
    // let isValid = false;
    let issues: (z.ZodIssue | undefined)[] = [];

    const fieldSchema = state.schema?.shape[name];
    // Attempt to parse the value using the field schema
    const parseResult = fieldSchema?.safeParse(key);
    if (!parseResult?.success) {
      issues = parseResult?.error.issues ?? [];
    }

    // Update the error messages array
    const current = (state.errors[name] ?? [])[0];
    const newIssues = issues[0];

    if (current?.code !== newIssues?.code) {
      bloc.patch({
        errors: { ...state.errors, [name]: issues }
      });
    }
  };

  const handleCheckboxChange = (value: string[]) => {
    bloc.handleChange(name, value);
    const fieldError = state.errors[name]?.[0];
    if (!bloc.props?.validateOnChange && !fieldError) return;

    // Initialize validation flag and error messages array
    // let isValid = false;
    let issues: (z.ZodIssue | undefined)[] = [];

    const fieldSchema = state.schema?.shape[name];
    // Attempt to parse the value using the field schema
    const parseResult = fieldSchema?.safeParse(value);
    if (!parseResult?.success) {
      issues = parseResult?.error.issues ?? [];
    }

    // Update the error messages array
    const current = (state.errors[name] ?? [])[0];
    const newIssues = issues[0];

    if (current?.code !== newIssues?.code) {
      bloc.patch({
        errors: { ...state.errors, [name]: issues }
      });
    }
  };

  const handleDateRangeChange = (value: DateRange) => {
    bloc.handleChange(name, value);
    const fieldError = state.errors[name]?.[0];
    if (!bloc.props?.validateOnChange && !fieldError) return;

    // Initialize validation flag and error messages array
    // let isValid = false;
    let issues: (z.ZodIssue | undefined)[] = [];

    const fieldSchema = state.schema?.shape[name];
    // Attempt to parse the value using the field schema
    const parseResult = fieldSchema?.safeParse(value);
    if (!parseResult?.success) {
      issues = parseResult?.error.issues ?? [];
    }

    // Update the error messages array
    const current = (state.errors[name] ?? [])[0];
    const newIssues = issues[0];

    if (current?.code !== newIssues?.code) {
      bloc.patch({
        errors: { ...state.errors, [name]: issues }
      });
    }
  };

  const changeHandlers: Record<string, unknown> = {
    textarea: handleInputChange,
    date: handleDateChange,
    daterange: handleDateRangeChange,
    select: handleSelectChange,
    autocomplete: handleSelectChange,
    radio: handleRadioChange,
    checkbox: handleCheckboxChange,
    default: handleInputChange
  };

  const additionalProps = useMemo(() => {
    const dynamicProps: Record<string, unknown> = {
      ref,
      onChangeCapture: changeHandlers[type] || changeHandlers.default
    };

    if (type === "checkbox") {
      dynamicProps.onChange = dynamicProps.onChangeCapture;
    }

    if (type === "date") {
      dynamicProps.onDateChange = dynamicProps.onChangeCapture;
    }

    if (type === "textarea") {
      dynamicProps.onInput = dynamicProps.onChangeCapture;
    }

    if (type === "daterange") {
      dynamicProps.onChange = dynamicProps.onChangeCapture;
    }

    if (type === "autocomplete") {
      const { defaultValue, defaultSelectedKey } =
        userDefinedProps as unknown as AutocompleteProps<{ id: "" }>;
      dynamicProps.onSelectionChange = dynamicProps.onChangeCapture;
      dynamicProps.defaultSelectedKey = defaultSelectedKey ?? defaultValue;
    }

    if (type === "select") {
      dynamicProps.onSelectionChange = dynamicProps.onChangeCapture;
    }

    return {
      ...dynamicProps,
      error: state.errors[name]?.[0],
      isInvalid: !!state.errors[name]?.[0]
    };
  }, [state.errors, state.schema, name, type]);

  useEffect(() => {
    return () => {
      bloc.unregisterAutoFormField(name);
    };
  }, []);

  return [
    { ...fieldPropsGenerated, ...additionalProps } as AutoFormRegisterProps<T>
  ];
}
