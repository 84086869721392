export enum ErrorCode {
  invalidUserData = "10001",
  emailVerificationFailed = "10002",
  invalidCredentials = "10003",
  invalidToken = "10004",
  dataValidation = "10005",
  storateInternalError = "10006",
  fileNotFound = "10007",
  externalService = "10008",
  permissionsDenied = "10009",
  questionnaireAnswersNotFound = "10010",
  userIneligible = "10011",
  subscriptionIdNotFound = "10012",
  subscriptionItemNotFound = "10013",
  subscriptionSkuAlreadyExists = "10014",
  couponNotFound = "10023",
  subscriptionInvalidPaymentFrequency = "10015",
  treatmentPlanItemNotFound = "10016",
  addressNotFound = "10017",
  avatarNotFound = "10018",
  invalidApiToken = "10019",
  stripeVerificationFailed = "20001",
  stripeInvalidData = "20002",
  stripeUnexpectedEvent = "20003",
  stripeCustomerNotFound = "20004",
  truepillInvalidData = "21001",
  truepillMissedCallbackType = "21002",
  truepillConsultationNotFound = "21003",
  truepillUnknownStatus = "21004",
  truepillPrescriptionNotFound = "21005",
  truepillPrescriptionAlreadyExists = "21006",
  pcpSearchInvalidData = "32072",
  twoFactorAuthRequired = "10025",
  initialApplicationDateBeforeMedicationAddedToPlan = "32084",
  unknown = "0"
}
