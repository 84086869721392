import { Cubit } from "blac";
import SanityService, {
  SanitySanctuaryHealthVideoData
} from "src/api/SanityService";
import VimeoService, { VimeoVideoMetaData } from "src/api/VimeoService";
import reportErrorSentry from "src/lib/reportErrorSentry";

export interface VideoData {
  thumb: string;
  width: number;
  height: number;
  fullscreen: boolean;
  inline?: boolean;
  title: string;
  videoWidth?: number;
  videoHeight?: number;
  ended?: boolean;
  src?: string;
}

export type VideoPlayerLibInterface = {
  on: (event: string, callback: () => void) => void;
  off: (event: string, callback: () => void) => void;
  play: () => Promise<void>;
  pause: () => void;
  currentTime: () => number;
  duration: () => number;
  isFullscreen: () => boolean;
  dispose: () => void;
  isDisposed: () => boolean;
  readyState: () => number;
  networkState: () => number;
  currentSrc: () => string;
  bufferedPercent: () => number;
  error: () => MediaError | null;
};

export default class VideoPlayerCubit extends Cubit<
  Record<string, VideoData | undefined>
> {
  portalTarget: HTMLElement | null = null;

  metadata: Record<string, VimeoVideoMetaData | undefined> = {};

  constructor() {
    super({});
  }

  public readonly getVideoSource = async (
    id: number | string
  ): Promise<
    {
      src: string;
      type: "video/mp4";
    }[]
  > => {
    if (typeof id === "number") {
      return this.getVimeoVideoSource(id);
    } else {
      return this.getSanityVideoSource(id);
    }
  };

  public readonly getSanityVideoSource = async (
    id: string
  ): Promise<
    {
      src: string;
      type: "video/mp4";
    }[]
  > => {
    const inState = this.state[id] ?? (await this.loadSanityData(id));
    if (!inState || !inState.src) {
      return [];
    }

    return [
      {
        type: "video/mp4",
        src: inState.src
      }
    ];
  };

  public readonly getVimeoVideoSource = async (
    id: number
  ): Promise<
    {
      src: string;
      type: "video/mp4";
    }[]
  > => {
    try {
      const vimeoData = VimeoService.fetchVideoMetadata(id);
      const { files } = await vimeoData;

      // select based on the width
      const selected =
        files?.find((file) => file.rendition === "720p") ?? files?.[0];

      if (!selected) {
        return [];
      }

      return [
        {
          type: "video/mp4",
          src: selected.link
        }
      ];
    } catch (error) {
      reportErrorSentry(error);
    }
    return [];
  };

  public readonly addVideoData = (id: number | string): void => {
    if (typeof id === "number") {
      void this.loadVimeoData(id);
    } else {
      void this.loadSanityData(id);
    }
  };

  public readonly addVimeoVideo = (id: number): void => {
    void this.loadVimeoData(id);
  };

  public readonly addSanityVideo = (id: string): void => {
    void this.loadSanityData(id);
  };

  isVideoWatched = (id: number | string): boolean => {
    const endedStorage = !!localStorage.getItem(`9am-video-${id}-watched`);
    const endedState = this.state[id]?.ended ?? false;
    return endedStorage || endedState;
  };

  markVideoEnded = (id: number | string): void => {
    localStorage.setItem(`9am-video-${id}-watched`, "true");
    const current = this.state[id];

    if (!current) {
      return;
    }

    this.emit({
      ...this.state,
      [id]: { ...current, ended: true }
    });
  };

  private readonly loadVimeoData = async (id: number): Promise<void> => {
    try {
      const data = this.metadata[id] ?? (await VimeoService.getVideoMeta(id));
      this.metadata[id] = data;
      const { pictures } = data;

      const mainThumb = (pictures?.sizes ?? []).find(
        (size) => size.width === 640
      );
      if (!mainThumb) {
        return;
      }

      const newItem: VideoData = {
        fullscreen: false,
        thumb: mainThumb.link ?? "",
        width: mainThumb.width ?? 0,
        height: mainThumb.height ?? 0,
        title: data.name ?? "",
        videoWidth: data.width ?? 0,
        videoHeight: data.height ?? 0
      };

      this.emit({
        ...this.state,
        [id]: newItem
      });
    } catch (e: unknown) {
      reportErrorSentry(e);

      this.emit({
        ...this.state,
        [id]: {
          fullscreen: false,
          thumb: "",
          width: 0,
          height: 0,
          title: ""
        }
      });
    }
  };

  private sanityMetadata: Record<
    string,
    SanitySanctuaryHealthVideoData | null
  > = {};

  private readonly loadSanityData = async (
    id: string
  ): Promise<VideoData | null> => {
    try {
      const data =
        this.sanityMetadata[id] ?? (await SanityService.resolveVideoById(id));
      this.sanityMetadata[id] = data;

      const newItem: VideoData = {
        fullscreen: false,
        thumb: data?.photoUrl ?? "",
        width: 0,
        height: 0,
        title: data?.title ?? "",
        videoWidth: 0,
        videoHeight: 0,
        src: data?.landscapeVideo?.path
      };
      const vimeoOverride = data?.landscapeVideo?.vimeoIdPathOverride;
      if (vimeoOverride) {
        const idNumber = Number(vimeoOverride);
        const [vimeoSource] = await Promise.all([
          this.getVimeoVideoSource(idNumber),
          this.loadVimeoData(idNumber)
        ]);
        const vimeoData = this.state[idNumber];
        if (vimeoData && vimeoSource.length > 0) {
          newItem.thumb = vimeoData.thumb;
          newItem.src = vimeoSource[0].src;
        }
      }

      this.emit({
        ...this.state,
        [id]: newItem
      });
      return newItem;
    } catch (e: unknown) {
      reportErrorSentry(e);

      this.emit({
        ...this.state,
        [id]: {
          fullscreen: false,
          thumb: "",
          width: 0,
          height: 0,
          title: ""
        }
      });
    }
    return null;
  };

  getVideoIdFromSrc = (src: string): number | string | null => {
    const vimeoId = VimeoService.extractIdFromUrl(src);
    const sanityId = SanityService.checkIsVideoId(src);
    return vimeoId ?? sanityId;
  };
}
